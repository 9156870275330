var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"mx-2"},[_c('h3',{staticClass:"mt-5"},[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "mesh.studyenvironment", true, "Ambientes de Aprendizajes" ))+" "),_c('AlternativeNameButton',{attrs:{"table_name":'mesh.studyenvironment',"allows_crud":_vm.allows_crud}})],1),_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"col",staticStyle:{"text-align":"left"}},[(_vm.allows_crud)?_c('ButtonMedium',{directives:[{name:"can",rawName:"v-can",value:('mesh.add_studyenvironment'),expression:"'mesh.add_studyenvironment'"}],attrs:{"tooltip_text":`Agregar ${_vm.$getVisibleNames(
            'mesh.studyenvironment',
            false,
            'Ambiente de Aprendizaje'
          )}`,"text_button":`Agregar`,"click_button":() => _vm.$bvModal.show(`new-study-environment-modal`),"icon":'plus',"variant":'primary'}}):_vm._e()],1)]),_c('GenericBTable',{staticClass:"study-environment-table",attrs:{"items":_vm.study_environments,"pagination":10,"fields":_vm.studyEnvironmentsFields,"filterCustom":_vm.filterCustom,"filter":_vm.input_search,"show_pagination":true,"search_filter":true,"columns_display":true,"allows_crud":_vm.allows_crud,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"emitChangeSearchField":_vm.changeInputSearch,"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"cell(time_allocation)",fn:function(row){return [_c('div',{staticClass:"time-allocation-container"},_vm._l((_vm.getTimeAllocation(row.item.id)),function(item){return _c('div',{key:item.id,staticClass:"time-allocation-div"},[_vm._v(" "+_vm._s(item.name)+" ")])}),0)]}},(_vm.allows_crud)?{key:"cell(actions)",fn:function(row){return [_c('ButtonSmall',{class:``,attrs:{"tooltip_text":`Editar ${_vm.$getVisibleNames(
            'mesh.studyenvironment',
            false,
            'Ambiente de Aprendizaje'
          )}`,"click_button":() => _vm.$bvModal.show(`edit-study-environment-modal-${row.item.id}`),"icon":'square'}}),_c('ButtonSmall',{attrs:{"click_button":() => _vm.askForDeleteStudyEnvironment(row.item.id),"tooltip_text":`Eliminar ${_vm.$getVisibleNames(
            'mesh.studyenvironment',
            false,
            'Ambiente de Aprendizaje'
          )}`,"icon":'trash'}}),_c('b-modal',{attrs:{"id":`edit-study-environment-modal-${row.item.id}`,"title":`Editar ${_vm.$getVisibleNames(
            'mesh.studyenvironment',
            false,
            'Ambiente de Aprendizaje'
          )}`,"size":"md","hide-footer":""}},[_c('StudyEnvironmentForm',{attrs:{"StudyEnvironment":row.item,"show_title":false},on:{"updated":_vm.slotUpdatedStudyEnvironment}})],1)]}}:null],null,true)})],1),_c('b-modal',{attrs:{"id":`new-study-environment-modal`,"hide-footer":true,"title":`Crear ${_vm.$getVisibleNames(
      'mesh.studyenvironment',
      false,
      'Ambiente de Aprendizaje'
    )}`,"size":"md"}},[_c('StudyEnvironmentForm',{attrs:{"show_title":false},on:{"created":_vm.slotCreatedStudyEnvironment}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }